.slideshow-container {
    width: 80%;
    height: 500px; /* Set a fixed height according to your preference */
    margin: auto; /* Center the slideshow */
    position: relative; /* For positioning the next button or other elements */
    overflow: hidden; /* Hide the overflow */
  }
  
  .gallery-image {
    width: 100%; /* Make the image take the full width of the container */
    height: 100%; /* Make the image take the full height of the container */
    object-fit: contain; /* This will ensure that the aspect ratio of the image is maintained without cropping */
  }
  