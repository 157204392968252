.landing-page .hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    padding: 0 5%;
    background:  url('../../../public/3.webp') no-repeat center center;

    background-size: cover;
    margin-bottom: 0px;
}

.landing-page .moon-animation img {
    max-width: 50%;
    height: auto;
    
}


.landing-page .title h1 {
    color: #fff;
}

.landing-page .roadmap-container {
    padding: 50px 5%;
    color: #fff;
}

.landing-page .roadmap-entry p {
    max-width: 70%; /* Adjust the max-width as needed */
    margin: 0 auto; /* This centers the paragraph if the width is less than 100% */
    padding: 0 20px; /* Optional: adds padding inside the paragraph for better readability */
    text-align: center; /* Optional: Justifies the text for better readability */
  }

.landing-page .roadmap-entry.visible {
    opacity: 1;
    transform: translateY(0);
}




.roadmap-container {
    
    text-align: center; /* Center the text */
}

.roadmap-entry {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically within the container */
    justify-content: space-between; /* Space out the child elements */
    margin-bottom: 150px; /* Increase space between sections */
    padding: 20px; /* Add some padding inside the sections */
    border-radius: 10px; /* Optional: Rounded corners for sections */
    background: rgba(116, 112, 112, 0.617); /* Optional: Semi-transparent background */
    backdrop-filter: blur(100px); /* Optional: Blur effect for the background */
    transition: opacity 1.5s, transform 1.9s;
    opacity: 0;
    transform: translateY(70px);
    margin-top: '30px';

}




.roadmap-entry-centered {
    opacity: 0;
    transform: translateY(70px);
    transition: opacity 1.5s, transform 1.9s;
    margin-bottom: 150px; /* Increase space between sections */
    padding: 20px; /* Add some padding inside the sections */
    border-radius: 10px; /* Optional: Rounded corners for sections */
    background: rgba(160, 158, 158, 0.917); /* Optional: Semi-transparent background */
    backdrop-filter: blur(100px); /* Optional: Blur effect for the background */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
    margin-top: 40px;


}



.roadmap-entry-contact {
    opacity: 0;
    margin-top: 40px;
    transform: translateY(70px);
    transition: opacity 1.5s, transform 1.9s;
    margin-bottom: 150px; /* Increase space between sections */
    padding: 20px; /* Add some padding inside the sections */
    border-radius: 10px; /* Optional: Rounded corners for sections */
    background: rgba(160, 158, 158, 0.917); /* Optional: Semi-transparent background */
    backdrop-filter: blur(100px); /* Optional: Blur effect for the background */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */


}

.roadmap-entry-contact.visible {
    opacity: 1;
    transform: translateY(0);
}

.roadmap-entry-contact h2, .roadmap-entry p {
    margin: 20px 0; /* Add more space around headings and paragraphs */
    text-align: center; /* Ensure text is centered */
}

.roadmap-entry-centered.visible {
    opacity: 1;
    transform: translateY(0);
}

.roadmap-entry-centered h2, .roadmap-entry p {
    margin: 20px 0; /* Add more space around headings and paragraphs */
    text-align: center; /* Ensure text is centered */
}

.roadmap-entry.visible {
    opacity: 1;
    transform: translateY(0);
}
.roadmap-graphic-centered {
    text-align: center; /* Center the image */
  }
  
  .roadmap-graphic-centered img {
    margin: 0 auto; /* Center the image within the div */
    display: block; /* Ensure the image is block level for proper centering */
    max-width: 100%; /* Ensure the image is responsive */
    height: auto; /* Maintain aspect ratio */
  }

.roadmap-entry.visible {
    opacity: 1;
    transform: translateY(0);
}

/* Text box styling */
.roadmap-entry .text-box {
    flex: 1; /* Allow text box to grow */
    padding-right: 20px; /* Space between text box and image */
}

/* Image box styling */
.roadmap-entry .image-box {
    flex: 1; /* Allow image box to grow */
    max-width: 50%; /* Limit image box width */
    background-size: cover; /* Cover the entire div with the background */
    background-position: center; /* Center the background image */
    height: 350px; /* Adjust height as needed */
}

.roadmap-entry {
    flex-direction: row; /* Ensure the layout is always row, so the image is on the right */
}

@media (max-width: 768px) {
    .roadmap-entry {
        flex-direction: column; /* On smaller screens, stack them */
    }
    .roadmap-entry .image-box {
        order: -1; /* Ensure the image stays above the text on smaller screens */
    }
    .roadmap-entry-centered {
      
        transform: translateX(00px);
    
    
    }
}

.roadmap-entry h2, .roadmap-entry p {
    margin: 20px 0; /* Add more space around headings and paragraphs */
    text-align: center; /* Ensure text is centered */
}

.roadmap-tech {
    margin-bottom: 150px; /* Increase space between sections */
    padding: 20px; /* Add some padding inside the sections */
    border-radius: 10px; /* Optional: Rounded corners for sections */
    background: rgba(255, 255, 255, 0.1); /* Optional: Semi-transparent background */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
}



.roadmap-tech h2, .roadmap-entry p {
    margin: 20px 0; /* Add more space around headings and paragraphs */
    text-align: center; /* Ensure text is centered */
}

/* Optional: Add a class for decorative graphics */
.roadmap-graphic {
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 350px; /* Adjust the height for your graphics */
    width: 100%; /* Ensure the graphic takes full width of its container */
    margin-top: 20px; /* Space between graphic and text */
    margin-bottom: 40px;
}

.roadmap-techgraphic {
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 250px; /* Adjust the height for your graphics */
    width: 100%; /* Ensure the graphic takes full width of its container */
    margin-top: 20px; /* Space between graphic and text */
    margin-bottom: 40px;
}

.roadmap-graphic::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none; /* This ensures clicks go through the pseudo-element */
}

.roadmap-techgraphic::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 1) 100%);
    pointer-events: none; /* This ensures clicks go through the pseudo-element */
}


.title-button-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically (if needed) */
    margin-right: 15%; /* Adjust as needed */
}

@media (max-width: 768px) {
    .title-button-container {
        margin-right: 0%; /* No margin on the right on mobile devices */
    }
    .landing-page .moon-animation img {
        max-width: 80%;
        height: 80%;        
    }
}


  .top-section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1; /* Ensure it's above other content */
}


@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 3s ease-in ;
  }


  .FooterWrapper {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #333;
    color: #fff;
}

.MapButton img {
    width: 250px;
    height: 250px;
    margin-right: 20px; /* Space between the map image and contact info */
    cursor: pointer; /* Indicates the image is clickable */
    border-radius: 5px; /* Optional: Rounds the corners of the image */
}

.ContactInfo {
    display: flex;
    flex-direction: column;
}

.PhoneNumber {
    font-size: 1.2rem;
    margin-bottom: 10px; /* Space between the phone number and the email button */
}

.EmailButton {
    display: inline-block;
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none; /* Removes underline from the link */
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: #0056b3; /* Darkens the button on hover */
    }
}

.services-list {
    list-style-type: none; /* Removes default bullet points */
    padding: 0; /* Removes default padding */
  }
  
  .services-list li {
    margin-bottom: 15px; /* Adds space between list items */
    text-align: left; /* Aligns text to the left */
  }
  
  .services-list li strong {
    font-weight: bold; /* Makes the service name bold */
  }

  .slideshow-container {
    text-align: center; /* Center the slideshow container */
    position: relative; /* Allows absolute positioning for contained elements */
  }
  
  .slideshow-container img {
    max-width: 100%; /* Ensure the image fits the container */
    height: auto; /* Maintain aspect ratio */
  }
  
  .slideshow-container button {
    position: absolute; /* Position the button over the slideshow */
    bottom: 10px; /* Position at the bottom of the slideshow */
    left: 50%; /* Center the button */
    transform: translateX(-50%); /* Center the button accurately */
  }
  

  .gallery-slideshow-container img {
    max-height: 600px; /* Adjusts the maximum height of images */
    max-width: 75vw; /* Maintains the aspect ratio of images */

    width: auto; /* Maintains the aspect ratio of images */
    object-fit: contain; /* Ensures the image is scaled to fit within the container */
    margin: auto; /* Centers the image within the container */
    margin-bottom: 120px;
  }
  
  
  /* Styles for the slideshow navigation buttons */
  .gallery-slideshow-container button {
    background-color: #EADAB6; /* A soft, gold-like color for the button background */
    color: #000; /* Black text color */
    padding: 10px 20px; /* Padding for a larger clickable area */
    border: none; /* No border for a cleaner look */
    border-radius: 5px; /* Rounded corners for the buttons */
    cursor: pointer; /* Changes the cursor to a pointer to indicate a clickable button */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for color changes */
    margin: 5px; /* Adds space around the buttons */
    margin-bottom: 40px;

  }
  
  .gallery-slideshow-container button:hover {
    background-color: #FFBFA9; /* A lighter color on hover for visual feedback */
    color: #fff; /* Changes text color to white on hover */
  }
  